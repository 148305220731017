.handsontable {
    @each $name, $color in $colors {
        .-#{$name} {
            background-color: wcolor($color, 10%);
        }
    }

    .-sum {
        @each $name, $color in $colors {
            .-#{$name} {
                background-color: wcolor($color, 20%);
            }
        }
    }

    .-edited {
        background-color: wcolor($red, 25%);
        border-bottom: 1px solid $red;
    }

    .-selected {
        color: $blue !important;
    }

    input[type=checkbox]:checked {
        filter: invert(100%) hue-rotate(18deg) brightness(2);
    }
}

.report-table {
    thead {
        tr {
            th, th > div, th > .relative {
                padding: 0;
                height: .375rem !important;
                background-color: wcolor($grey-blue, 75%);
                color: wcolor($grey-blue, 50%);;

                > .colHeader {
                    font-size: .5rem;
                }
            }
        }
    }

    tbody {
        tr {
            th, th > div, th > .relative {
                padding: 0;
                width: .375rem !important;
                background-color: wcolor($grey-blue, 75%);
                color: wcolor($grey-blue, 75%);
            }
        }
    }

    td {
        &.-header {
            vertical-align: bottom;
            background-color: wcolor($grey-blue, 25%);
            text-align: center !important;
        }

        &.-group {
            text-align: left !important;
            font-weight: bold;
            background-color: wcolor($grey-blue, 15%);
        }

        &.-fontBold {
            font-weight: bold;
        }

        &.-light_yellow {
            background-color: wcolor($yellow, 5%);
        }

        &.-light_blue {
            background-color: wcolor($blue, 5%);
        }

        &.-light_grey {
            background-color: wcolor($grey, 5%);
        }

        &.-light_purple {
            background-color: wcolor($purple, 5%);
        }
    }
}

.form-datatable {
    padding: .25rem;

    > ._header {
        padding: .5rem;
        display: flex;
        align-items: center;
        min-height: 40px;

        > ._title {
            font-size: 14pt;
            color: $grey-blue;
        }
    }

    > .datatable-container {
        border: 1px solid wcolor($grey, 25%);
        border-radius: .125rem;
        background-color: wcolor($grey-blue, 10%);

        > ._protection {
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
        }
    }
}


.cell-small {
    line-height: 20px;
    font-size: .8rem;
}

.group-roles {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: .25rem;
    display: flex;
    white-space: nowrap;
    overflow: auto;

    > ._group {
        vertical-align: top;
        display: inline-block;
        margin: 4px;
        min-width: 250px;
        transition: border-color 0.2s;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        padding: .5rem;
        background: $white;

        > ._title {
            font-size: 11pt;
            color: $grey-blue;
            text-align: center;
        }

        > ._data {
            height: 300px;
            position: relative;

            ul {
                list-style: none;
                padding: 0;

                ul {
                    padding-left: 1.5rem;
                }
            }
        }

        .rst__placeholder {
            z-index: 1000;
        }

    }

    .rct-title {
        font-size: 11pt;
    }
}

.group-role-item {
    margin: .125rem;
    padding: .125rem .25rem;
    border-radius: .25rem;
    display: flex;
    color: wcolor($grey, 70%);
    cursor: pointer;

    &:hover {
        background-color: wcolor($orange, 15%);
        color: $orange;

        > ._icon:before {
            content: "\f055";
        }
    }

    &.-active {
        background-color: wcolor($green, 15%);
        color: $green;

        &:hover {
            background-color: wcolor($red, 15%);
            color: $red;
            > ._icon {
                font-weight: 300;
                &:before {
                    content: "\f057";
                }
            }
        }

        > ._icon {
            font-weight: 900;
            &:before {
                content: "\f058";
            }
        }
    }

    ._icon {
        width: 1.5rem;
        line-height: 1.5rem;
        font-size: 1rem;
    }

    ._text {
        line-height: 1.5rem;
    }
}

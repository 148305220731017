.document-modal {
    .form-modal_size {
        max-width: 1100px !important;
    }
}

.document-box {
    margin-top: .75rem;
    padding: 1rem;

    > ._header {
        display: flex;
        flex: 1;
        align-items: center;
        background-color: wcolor($grey-blue, 10%);
        padding: .5rem;
        margin: -.74rem;
        border-radius: .25rem;

        > ._text {
            margin: 0 1rem;
            font-size: 14pt;
            flex: 1;

            > span {
                margin-right: .75rem;
            }

            > .button {
                margin-left: auto;
            }

            > ._type {
                color: $red;
            }

            > ._vtype {
                color: $grey-blue;
            }

            > ._license-label {
                margin-right: .5rem;
            }

            > ._license {
                color: $purple;
            }

            > ._new {
                font-size: 12pt;
                color: $green;
            }

            > ._old {
                font-size: 12pt;
                color: $blue;
            }
        }
    }

    .docform-input-new {
        display: flex;

        .input-checkbox {
            white-space: nowrap;
        }

        .input-text {
            width: 150px;
            margin-left: .5rem;
        }

        .button {
            margin-left: .5rem;
        }
    }

    .document-paylot-div {
        display: flex;

        .input-checkbox {
            white-space: nowrap;
        }

        .input-text {
            padding-left: 0;

            .MuiOutlinedInput-adornedEnd {
                padding-right: .5rem;
            }

            .MuiInputBase-input {
                padding-left: 0;
                text-align: center;
            }

            .MuiInputAdornment-positionEnd {
                margin-left: 0;
            }
        }
    }
}

.document-split {
    height: 1px;
    margin: 1.5rem 0 .5rem 0;
    border-top: 1px dashed wcolor($grey, 40%);
}

.document-paylots {
    > ._header {
        padding: .5rem 1rem;
        font-size: 13pt;
        background-color: wcolor($grey-blue, 10%);
        border-radius: .25rem;
        margin-bottom: 0.25rem;
        line-height: 30px;

        > strong {
            margin: 0 .5rem;
            color: $red;
        }
    }
}


//////////////////////


.document-paylot {
    > ._header {
        display: flex;
        align-items: center;
        margin: .5rem 0;

        ._count {
            background-color: $grey-blue;
            padding: .25rem 1rem;
            color: $white;
            border-radius: .25rem;

            &.-lot_0, &.-lot_1 {
                background-color: $green;
            }
        }

        ._summary {
            margin-left: auto;
        }
    }

    > ._lots {
        border: 1px solid wcolor($grey, 50%);
        border-radius: .25rem;
        padding: .25rem;

        ._lot {
            width: 80px;
        }

        ._due {
            width: 240px;
            margin-left: .5rem;
        }

        ._pay {
            width: 440px;
            margin-left: .5rem;
        }

        ._note {
            margin-left: .5rem;
            flex-grow: 1;
        }

        > ._head {
            font-size: 10pt;
            font-weight: bold;
            display: flex;
            text-align: center;
            color: $grey;
            padding: .125rem .25rem;
        }


        > ._item {
            display: flex;
            border-radius: .25rem;
            padding: .25rem;
            margin: .25rem;

            &:hover {
                background-color: wcolor($blue, 10%);
            }

            &.-can-edit {
                cursor: pointer;

                &:hover {
                    background-color: wcolor($orange, 10%);
                }
            }

            > ._lot {
                text-align: center;
                padding-top: .25rem;

                > ._number {
                    font-size: 18pt;
                }

                > ._status {
                    font-size: 9pt;
                }
            }

            > ._due, > ._pay {
                > ._box {
                    background-color: $white;
                    display: flex;
                    border: 1px solid wcolor($grey, 50%);
                    border-radius: .25rem;
                    padding: .5rem;

                    > ._date {
                        font-size: 14pt;
                        width: 100px;
                        text-align: center;
                        line-height: 45px;
                    }

                    > ._money {
                        flex-grow: 1;
                        text-align: right;

                        > ._label {
                            line-height: 15px;
                            font-size: 10pt;
                            color: $grey;
                        }

                        > ._value {
                            line-height: 30px;
                            font-size: 16pt;
                        }
                    }
                }
            }

            > ._pay {
                > ._box {
                    > ._detail {
                        width: 200px;

                        ol {
                            margin: 0;
                            min-height: 45px;

                            li {
                                font-size: 9pt;
                                color: $grey-blue;
                            }
                        }
                    }
                }
            }

            > ._note {
                font-size: 10pt;
                color: $grey;
            }
        }
    }
}

.customer-view-box {
    position: relative;

    > .btn-edit {
        position: absolute;
        right: .5rem;
    }

    .detail-item {
        margin-top: 0.05rem;
        margin-bottom: 0.05rem;
        font-size: 10.5pt;

        > ._value {
            color: $black;
        }
    }
}


.coverage {
    display: flex;

    .col-grow {
        flex-grow: 1;
    }

    .coverage-detail {
        &:last-child {
            border-right: none;
        }

        padding: .5rem .25rem .5rem 1rem;
        border-right: 1px solid wcolor($grey, 30%);

        h5 {
            font-size: 12pt;
            font-weight: bold;
        }

        h6 {
            font-size: 10.5pt;
            font-weight: normal;
            color: $grey-blue;
        }
    }


    ol {
        padding-left: 1.5rem;
        counter-reset: item;

        li {
            display: block;
            text-indent: -1.5rem;
            font-size: 10.5pt;
        }

        > li {
            &:before {
                color: $grey-blue;
                margin-right: .5rem;
                counter-increment: item;
                content: counters(item, ".") " )";
            }

            > ol li {
                &:before {
                    content: counters(item, ".");
                }
            }
        }
    }

    ._text {
        color: $grey-blue;
    }

    ._data {
        text-indent: 0;
        display: flex;
        padding: .25rem;

        ._head {
            width: 80px;
        }

        ._value {
            flex-grow: 1;
            text-align: right;
            color: $red;
        }

        ._count {
            width: 20px;
            text-align: center;
            color: $red;
        }

        ._count-unit {
            width: 20px;
            margin-left: .25rem;
        }

        ._unit {
            width: 60px;
            margin-left: .5rem;
        }

        ._unit-long {
            width: 100px;
            margin-left: .5rem;
        }
    }
}

.check-send-doc {
    input {
        margin-top: .25rem;
    }

    label {
        margin-left: .5rem;
        margin-bottom: 0;
    }

    ._log {
        margin-left: 1.375rem;
        display: block;
        font-size: .7rem;
    }
}

.bg-light {
    background-color: #f8f9fa !important;
}

.bg-active {
    background-color: rgba(0, 0, 0, 0.075)
}
.home-btn {
    display: block;
    text-align: center;
    padding: 1rem 0;

    &:hover {
        color: $mainColor;
    }

    > .icon {
        display: block;
        font-size: 64px;
        line-height: 80px;
        background: linear-gradient(to bottom, #01edd5 0%, #02abe9 100%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
    }

    > span {
        display: block;
        font-size: 12pt;
        line-height: 28px;
        margin-top: 5px;
    }
}

.coverage {
  display: flex;

  .col-grow {
    flex-grow: 1;
  }

  .coverage-detail {
    &:last-child {
      border-right: none;
    }

    padding: .5rem .25rem .5rem 1rem;
    border-right: 1px solid wcolor($grey, 30%);

    h5 {
      font-size: 12pt;
      font-weight: bold;
    }

    h6 {
      font-size: 10.5pt;
      font-weight: normal;
      color: $grey-blue;
    }
  }


  ol {
    padding-left: 1.5rem;
    counter-reset: item;

    li {
      display: block;
      text-indent: -1.5rem;
      font-size: 10.5pt;
    }

    > li {
      &:before {
        color: $grey-blue;
        margin-right: .5rem;
        counter-increment: item;
        content: counters(item, ".") " )";
      }

      > ol li {
        &:before {
          content: counters(item, ".");
        }
      }
    }
  }

  ._text {
    color: $grey-blue;
  }

  ._data {
    text-indent: 0;
    display: flex;
    padding: .25rem;

    ._head {
      width: 80px;
    }

    ._value {
      flex-grow: 1;
      text-align: right;
      color: $red;
    }

    ._count {
      width: 20px;
      text-align: center;
      color: $red;
    }

    ._count-unit {
      width: 20px;
      margin-left: .25rem;
    }

    ._unit {
      width: 60px;
      margin-left: .5rem;
    }

    ._unit-long {
      width: 100px;
      margin-left: .5rem;
    }
  }
}

.customer-vehicle {
  background-color: wcolor($grey-blue, 10%);

  .icon-button {
    padding: .5rem;

    .fa {
      height: 1.5rem;
      width: 1.5rem;
      font-size: 1.5rem;
    }
  }

  .input-text, .input-photo {
    background-color: $white;
  }
}
.login-container {
    position: relative;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 1000;
    background-color: wcolor($mainColor, 10%);

    &:before {
        content: '1';
        display: block;
        position: absolute;

        top: -50%;
        right: -40vw;

        border-radius: 50%;
        width: 200vh;
        height: 200vh;
        background-color: $white;
    }
}

.login-bg {
    height: 200px;
    position: relative;

    > svg {
        fill: $mainColor;
    }
}


.login-page {
    flex: 1;
    display: flex;
    border-radius: .5rem;
    padding: 1.5rem 1rem;
    z-index: 1000;

    align-items: center;
    justify-content: center;

    > ._image {
        width: 500px;

        img {
            max-width: 100%;
        }
    }

    > ._form {
        width: 300px;
        margin-left: 50px;
        margin-right: 200px;

        > ._title {
            text-align: center;
            font-size: 20pt;
            margin-bottom: .25rem;
            text-transform: uppercase;
            color: wcolor($mainColor, 55%);
        }

        > ._version {
            text-align: center;
            margin-bottom: 1rem;
            font-size: 10pt;
            color: wcolor($mainColor, 75%);
        }

        .inputbox {
            margin-top: 1.5rem;

            ._input-container {
                padding: .5rem;

                ._input {
                    border-radius: .5rem;
                }
            }
        }

        .btn {
            margin-top: 2rem;
            background-color: wcolor($mainColor, 75%);
            color: $white;
            padding: 1rem;
            border-radius: .5rem;

            &:hover {
                background-color: wcolor($mainColor, 55%);
            }

            &:focus {
                outline: 0;
                box-shadow: 0 0 0 0.2rem bcolor($mainColor, 75%);
            }
        }
    }
}

@include mobile {
    .login-container {
        &:before {
            display: none;
        }
    }
    .login-page {
        > ._image {
            display: none;
        }

        > ._form {
            margin: 0 auto;
        }
    }
}
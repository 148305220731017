$appbarMargin: 24px;

@mixin layoutColor($c) {
    .layout-top {
        @apply bg-#{$c}-500;
    }

    .layout-mainmenu {
        @apply bg-#{$c}-500;

        .un-icon-button {
            @apply text-#{$c}-200 hover:text-#{$c}-400 hover:bg-#{$c}-50;
        }
    }

    .layout-menuifo {
        @apply bg-#{$c}-700 text-#{$c}-100;
    }

    .layout-sidemenu {
        @apply bg-#{$c}-100;

        &.-mini {
            .layout-mainmenu {
                > ._user {
                    @apply bg-#{$c}-600;
                }
            }
        }
    }

    .layout-menu {
        > ._protect {
            .layout-menu-div {
                @apply border-#{$c}-300;
            }

            .layout-menu-header {
                @apply text-#{$c}-700;
            }

            .btn-coll > .un-icon-button {
                @apply text-#{$c}-500;
            }
        }
    }

    .un-menubar {
        @apply bg-#{$c}-100;
    }

    .un-menubar-item {
        @apply text-#{$c}-400 hover:bg-#{$c}-100;

        &:before {
            @apply bg-#{$c}-700;
        }

        &.-active {
            @apply bg-#{$c}-400 text-white;
        }
    }
}

@mixin menuColor($c) {
    @apply text-#{$c}-500 bg-#{$c}-100;

    &:hover {
        @apply bg-#{$c}-300 text-#{$c}-700;
    }

    &.-active {
        @apply bg-#{$c}-600 text-#{$c}-200;
    }
}

.layout {
    @apply flex flex-1;
    @include layoutColor('grey-blue');
    @each $color in $color-names {
        &.-#{$color} {
            @include layoutColor($color);
        }
    }
}

.layout-top {
    @apply fixed py-1 px-4 inset-x-0 top-0 z-10;
    .icon-button {
        i {
            text-align: center;
            width: 24px;
            font-size: 24px;
            line-height: 24px;
        }
    }
}

.layout-area {
    @apply flex flex-1 flex-row;
}

.layout-mainmenu {
    @apply flex relative h-12 px-2 items-center;
    ._user {
        @apply flex flex-1 justify-end;
        > .un-icon-button {
            @apply w-10 h-10 text-white text-center leading-10;
        }
    }
}

.layout-menuifo {
    @apply flex px-2 py-0.5 text-xs mb-2;
    > ._user {
        @apply ml-auto;
    }
}

.layout-sidemenu-cover {
    @apply flex;
}

.layout-sidemenu {
    @apply flex flex-col w-60;

    &.-grow {
        @apply flex-1;
    }

    &.-mini {
        @apply w-12;

        .layout-mainmenu {
            @apply p-0 justify-center;
            &:hover {
                @apply relative;
                > ._user {
                    @apply flex absolute shadow top-0 py-1 pr-2 w-32 z-40 rounded-r-lg;
                    left: 100%;

                    .un-icon {
                        @apply inline-block;
                        &.-menu {
                            @apply mr-2;
                        }
                    }
                }
            }

            ._user {
                @apply hidden;
                > .un-icon {
                    &.-menu {
                        @apply block w-12 text-center leading-10 text-white;
                    }
                }

                > .un-icon-button {
                    @apply m-0;
                }
            }
        }

        ._no-mini-show, .layout-menuifo {
            display: none;
        }

        .layout-menu {
            @apply overflow-visible pt-2;

            > ._protect {
                @apply overflow-visible;
            }

            .layout-menu-div {
                @apply mt-2 mx-1;
            }

            .layout-menu-header {
                @apply hidden;
            }

            .layout-menu-item {
                @apply relative block py-1.5 px-0 text-center;

                > .un-icon {
                    @apply m-0 text-xl;
                }

                > ._text {
                    @apply hidden;
                }
            }
        }
    }
}


.layout-menu {
    @apply flex flex-col relative flex-1;
    .btn-coll {
        text-align: center;

        > .icon-button {
            color: $mainColor;
        }
    }
}

.layout-menu-item {
    @apply flex items-center py-1 px-4 justify-start;
    .un-icon {
        @apply text-center w-6 text-lg mr-2;
    }

    @each $color in $color-names {
        &.-#{$color} {
            @include menuColor($color);
        }
    }
}

.layout-content {
    > ._protect {
        @apply absolute inset-0 flex flex-col overflow-auto p-4;
        > .un-menubar.-fix-top {
            @apply rounded-none p-0 border-none;
            > .un-menubar-item {
                @apply py-1;
            }
        }
    }
}

.paging-container {
    > .paging {
        > ._btn {
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }
}

.flex-break {
    flex-basis: 100%;
    height: .5rem;
}


.ss-crosshairs {
    @apply absolute border border-blue-500 rounded bg-blue-500 bg-opacity-50;
    z-index: 5000;
}

.ss-overlay {
    @apply fixed inset-0 bg-black bg-opacity-50;
    z-index: 4500;

    &.-start {
        @apply bg-transparent;
    }
}


.layout-mainmenu-btn {
    .un-dropdown-menu {
        @apply z-50 border-grey-blue-900 bg-grey-blue-800 rounded-lg shadow-2xl;
        width: 800px;
    }
}


.menumain-wrap {
    @apply overflow-auto px-3 pb-3;
    max-height: 90vh;
}

.menumain-btn {
    @apply flex items-center text-2xl opacity-75 text-white p-1.5 cursor-pointer hover:opacity-100;
}

.menumain-group_header {
    @apply w-full border-b border-dashed border-grey-blue-200 mt-3 mb-3;
    line-height: 0.1rem;

    span {
        @apply bg-grey-blue-800 text-grey-blue-50 px-2 ml-10;
    }
}

.menumain-group_item {
    @apply flex;
}

.menumain-group-core {
    @apply mt-2 -mx-1 mb-6 p-2 bg-grey-blue-900 rounded-lg;
    .menumain-item {
        @apply flex-1;
    }
}

.menumain-item_label {
    @apply w-28 py-2 text-sm text-grey-blue-200;
}

.menumain-items {
    @apply flex flex-1 flex-wrap;
    .menumain-item {
        @apply w-1/3;
    }
}

.menumain-item {
    @apply flex items-center py-1 px-3 rounded text-grey-blue-50 hover:bg-grey-blue-700;
    &:hover {
        ._icon-fav {
            @apply block;
        }
    }

    &.-active {
        @apply bg-grey-blue-600;
    }

    ._link {
        @apply flex flex-1 items-center;
    }

    ._icon {
        @apply w-10 text-2xl;
    }

    ._text {
        @apply flex-1 truncate;
    }

    ._icon-fav {
        @apply hidden cursor-pointer hover:text-orange-500;
        &.-active {
            @apply block font-bold text-orange-400;
        }
    }
}

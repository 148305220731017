// ---- for old lib 0.1.*

.input-checkbox.-no-pad {
    > ._check-container {
        padding: 0;
    }
}

.list-stack {
    &:last-child {
        border-bottom: none;
    }
}

mark {
    padding: 0;
    background: none;
}


.nr-auto + .btn-save {
    margin-left: 0 !important;
}

$colors: ("red":$red, "pink":$pink, "purple":$purple,
         "blue":$blue,
        "green":$green,  "yellow":$yellow,
        "orange":$orange,  "brown":$brown, "grey":$grey, "grey-blue":$grey-blue);

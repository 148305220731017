.input-text {
    .Mui-disabled {
        &.MuiInputBase-root {
            background: wcolor($grey, 15%);
        }

        input {
            color: $black;
        }
    }
}


.info-box {
    background-color: wcolor($grey-blue, 5%);
    padding: .25rem;

    .detail-item {
        font-size: 11pt;
    }
}

.detail-item {
    display: flex;
    margin-top: .25rem;
    margin-bottom: .25rem;

    > ._label {
        width: 130px;
        text-align: right;
        padding-right: 1rem;
        color: $grey-blue;

        &.-xs {
            width: 50px;
        }

        &.-sm {
            width: 80px;
        }
    }

    > ._value {
        flex-grow: 1;
    }

    ol {
        margin-bottom: 0;
    }
}

.detail-item-photo {
    margin: .25rem;

    &:hover {
        > ._img {
            border-color: $orange;
        }

        > ._title {
            color: $orange;
        }
    }

    > ._img {
        width: 80px;
        height: 80px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: .25rem;
        background-color: wcolor($grey, 15%);
        background-position: center;
        background-size: cover;
        display: flex;
        cursor: pointer;
        position: relative;
    }

    > .icon {
        line-height: 80px;
        width: 80px;
        height: 80px;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: .25rem;
        color: $red;
    }

    > ._title {
        text-align: center;
        font-size: 10pt;
        color: $grey;
    }
}

.detail-table {
    border: 1px solid wcolor($grey, 50%);
    border-radius: .25rem;
    padding: 1px;

    .table {
        td {
            padding: .5rem;
            font-size: 10.5pt;
        }

        .width-50 {
            width: 50px;
        }

        .width-100 {
            width: 100px;
        }

        .width-date {
            width: 100px;
        }

        .width-money {
            width: 90px;
        }
    }
}

.button.btn-icon {
    min-width: 0;
}

.button.btn-disabled {
    opacity: .3;
    border-color: #9a9da0;
    color: #343a40;
    cursor: not-allowed;
}

.btn-cell {
    height: 22px;
    font-size: 9pt !important;
    padding: 0 !important;
    width: 100% !important;
    display: block !important;
    min-width: 0 !important;

    > .MuiButton-label {
        line-height: 22px;
    }
}

.btn-delete {
    padding-left: 0;
    padding-right: 0;
    min-width: 0 !important;
    margin-right: auto !important;
}

.btn-save {
    min-width: 120px !important;
}


.dialog-icon {
    text-align: center;
    display: block;
    margin: 2rem auto;
    font-size: 5rem;
}

.dialog-link {
    padding: .5rem;
    border: 1px solid wcolor($grey-blue, 15%);

    > ._row {
        display: flex;

        + ._row {
            margin-top: .5rem;
            padding-top: .5rem;
            border-top: 1px solid wcolor($grey-blue, 15%);
        }

        > ._btn-link {
            flex: 1;
        }

        > ._btn-img {
            width: 100px;
        }
    }
}

.history-item {
    &:last-child {
        margin-bottom: 1rem !important;
    }

    .MuiAccordionSummary-root {
        background-color: wcolor($grey-blue, 15%);
        min-height: 40px !important;
        padding-left: .5rem;
    }

    .MuiAccordionSummary-content {
        margin: 0 !important;

        line-height: 22px;
        font-size: 10pt;
    }

    ._type {
        background-color: $black;
        color: $white;
        padding: 0 .5rem;
        font-size: 10pt;
        border-radius: .25rem;
        margin-right: .5rem;
    }


    ._by {
        margin-left: auto;
        margin-right: .5rem;
        font-weight: bold;
    }

    ._time {
        color: $grey-blue
    }

    .MuiAccordionDetails-root {
        padding: .5rem;
        max-height: 400px;
        overflow: auto;

        pre {
            flex: 1;
            margin: 0;
        }
    }
}

.history-item-type {
    background-color: $black;
    color: $white;
    padding: 0 .5rem;
    font-size: 10pt;
    border-radius: .25rem;
    margin-right: .5rem;
}


.form-modal_size.-sm .history-item {
    ._url, ._formnow {
        display: none;
    }
}

.list-note {
    font-size: 8pt;
    padding-left: 1.25rem;
    margin-bottom: 0;
    color: $grey;

    ul {
        padding-left: 1.25rem;
        margin-bottom: 0;
    }
}

.report-list {
    > h3 {
        font-size: 14pt;
        margin: 1rem 0;
        text-align: center;
        color: $grey-blue;
    }
}

.report-item {
    margin: .75rem 0;
    padding: .25rem .5rem;
    display: flex;
    height: 50px;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 11pt;

    &:hover {
        color: $orange;
    }
}


.pay-header {
    margin-top: -.5rem;
    background: $mainColor;
    border-radius: .25rem;
    padding: .5rem 1rem;
    color: $white;
    display: flex;
    align-items: center;

    > ._customer {
        flex-grow: 1;

        ._name {
            font-size: 18pt;

            > ._tel {
                margin-left: 1rem;
                font-size: 1rem;
            }
        }
    }

    > ._lot {
        padding: 0 1rem;
        text-align: center;

        > ._label {
            font-size: 10pt;
        }

        > ._value {
            font-size: 20pt;
        }
    }
}
